import React from 'react';
import member_1 from '../../../img/members/Pavel.jpg';
import member_2 from '../../../img/members/Lena.jpg';
import member_3 from '../../../img/members/Denis.jpg';
import member_4 from '../../../img/members/Artem.jpg';
import member_5 from '../../../img/members/Vadim.jpg';
import member_6 from '../../../img/members/Rustam.jpg';
import member_7 from '../../../img/members/Maxim.jpg';
import member_8 from '../../../img/members/Vika.jpg';
import member_9 from '../../../img/members/Alexandr.jpg';
import member_10 from '../../../img/members/Oleksii.jpg';
import member_11 from '../../../img/members/Anton.png';
import member_12 from '../../../img/members/Anastasia.jpg';
import member_13 from '../../../img/members/Danil.jpg';
import member_14 from '../../../img/members/Andrey.jpg';
import member_15 from '../../../img/members/Vitalik.jpg';
import member_16 from '../../../img/members/Pavlo_Tretiak.jpg';
import member_17 from '../../../img/members/Yurii_Makarenko.jpg';
import member_18 from '../../../img/members/Denys_Taradada.jpg';


export default () => (
  <div>
    <div className="row">
    <div className="text-box col-md-offset-1"> 
        
        <div className=" wow fadeInDown animated" data-wow-duration="1.5s">
          <h1>We Make Amazing Things Every Day</h1>
        </div>
        <div className=" wow fadeInUp animated" data-wow-duration="1.5s">
          <h5>HELLO, IT’S NICE TO MEET YOU</h5>
          <h5>We’re Your Team!</h5>
        </div>
          
        </div>
        <div className="team">
            <div className="view ">
                <img src={member_1} className="" alt=""/>
                <div className="mask">
                    <h2>Pavel Klimenkov</h2>
                    <h5>Founder, Senior developer</h5>
                    <p>High skilled developer who never resigns from a challenge. Get things done kind of guy. Friendly
                        and patient.</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_2} className="" alt=""/>
                <div className="mask">
                    <h2>Elena Berezovskaya</h2>
                    <h5>Coo</h5>
                    <p>Team lead with high experience. Deep understanding of the working processing. Good
                        problem-solving skills and logical thinking. Proactive, initiative and purposeful.</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_10} className="" alt=""/>
                <div className="mask">
                    <h2>Oleksii Babichev</h2>
                    <h5>Technical Lead</h5>
                    <p>Oleksii Babichev is Technical Lead, Architect. Since 2009 has been working in IT. He has good
                        experience with a web, mobile and backend developments. Oleksii likes technologies, every time
                        has been trying to find something new. </p>
                </div>
            </div>
            <div className="view ">
                <img src={member_3} className="" alt=""/>
                <div className="mask">
                    <h2>Denys Lysiansky</h2>
                    <h5>Senior developer</h5>
                    <p>Denys is a full-stack developer. Since 2012 he has worked on a lot of different projects using
                        lots of technologies. He loves the sea and jamon but obsessed with Ruby and modern front-end
                        frameworks like React and Angular.</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_4} className="" alt=""/>
                <div className="mask">
                    <h2>Artem Kuvychka</h2>
                    <h5>Senior developer</h5>
                    <p>High skilled developer who can work in a team and separately as well. Good problem solver.
                        Responsible and purposeful person.</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_5} className="" alt=""/>
                <div className="mask">
                    <h2>Vadym Yershov</h2>
                    <h5>Senior developer</h5>
                    <p>Skilled IT specialist with a decade of experience. He has a passion for developing quality
                        projects and keen on finishing the tasks despite the challenge given.</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_12} className="" alt=""/>
                <div className="mask">
                    <h2>Anastasia Klimenkova</h2>
                    <h5>Web developer</h5>
                    <p>Creative web developer with great taste and awesome design skills. Passionate and reliable.
                        Friendly and caring, great to communicate with.</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_6} className="" alt=""/>
                <div className="mask">
                    <h2>Rustam <br/> Alkyn</h2>
                    <h5>Senior developer</h5>
                    <p>Rustam is a good programmer. He programs from 6th grade. The first computer was zx spectrum</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_8} className="" alt=""/>
                <div className="mask">
                    <h2>Viktoriya Matdinova</h2>
                    <h5>back-end developer </h5>
                    <p>Purposeful, responsible and like to learn new technologies</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_9} className="" alt=""/>
                <div className="mask">
                    <h2>Alexandr Kuntsevich</h2>
                    <h5>QA Engineer</h5>
                    <p>People have already got used to trusting machines, Alexandr's goal as a QA engineer is to do
                        everything so that this trust is justified. He strives for continuous self-improvement and is
                        always open to helping others ...</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_7} className="" alt=""/>
                <div className="mask">
                    <h2>Maksym Ganzyur</h2>
                    <h5>QA Test Analyst</h5>
                    <p>An open-minded, communicative specialist with a responsible approach to work, and solid knowledge
                        of computer software and information technology.</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_11} className="" alt=""/>
                <div className="mask">
                    <h2>Anton Pastukhov</h2>
                    <h5>QA Test Analyst</h5>
                    <p>Skilled tester attentive, patient, diligent, meticulous, careful, responsible and able to work in
                        a team.</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_13} className="" alt=""/>
                <div className="mask">
                    <h2>Danil Kozub</h2>
                    <h5>Senior developer</h5>
                    <p>Developer with 9.5 years experience in social and mobile gamedev. Danil has experience in
                        development of a client and servergames. He is a good team lead with experience of managing
                        several projects.</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_14} className="" alt=""/>
                <div className="mask">
                    <h2>Andrii Driahlin </h2>
                    <h5>Senior developer</h5>
                    <p>Developer with 7 years experience in social gamedev and gambling. He has experience in frontend
                        web application develop and integration of 3rd party api. A good team worker and responsible
                        specialist.</p>
                </div>
            </div>
            <div className="view ">
                <img src={member_15} className="" alt=""/>
                <div className="mask">
                    <h2>Vitaly Buts</h2>
                    <h5>QA Test Analyst</h5>
                    <p>Vitaly has extensive experience working with documentation. Responsibly approaches to performance
                        of any tasks. Able to work productively in a team, focusing on ultimate goals.</p>
                </div>
            </div>

            <div className="view ">
                <img src={member_16} className="" alt=""/>
                <div className="mask">
                    <h2>Pavlo Tretiak</h2>
                    <h5>Senior developer</h5>
                    <p>Pavlo has been working as a programmer since 2012. Has a lot of experience in game and business software development. Responsible and passionate about learning new things.</p>
                </div>
            </div>

            <div className="view ">
                <img src={member_17} className="" alt=""/>
                <div className="mask">
                    <h2>Yurii Makarenko</h2>
                    <h5>BACK-END DEVELOPER</h5>
                    <p>Yurii is a Backend Developer with experience in Ruby and Ruby on Rails, skilled in collaboration and self-improvement. He enjoys challenges and adapting to new technologies, with a focus on delivering quality code and meeting deadlines.</p>
                </div>
            </div>

            <div className="view ">
                <img src={member_18} className="" alt=""/>
                <div className="mask">
                    <h2>Denys Taradada</h2>
                    <h5>Senior developer</h5>
                    <p>Highly-motivated IT specialist, with more than 4 years of hands-on experience designing, developing,
                        and implementing applications and solutions using a range of technologies
                    </p>
                </div>
            </div>

        </div>
    </div>
  </div>
);
