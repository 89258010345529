import React from 'react';

export default () => (
  <div  className="row">
    <div className="col-md-12">
        <div className="wow fadeInDown animated" data-wow-duration="1.5s">
          <h2 className = 'text-center'>Services</h2>
        </div>
        <div  className="wow fadeInUp animated" data-wow-duration="1.5s">
          <h3>Here Is What We Do</h3>
        </div>
    </div>

    <div className="col-md-6">
      <div className="feature-box wow fadeInLeft animated" data-wow-duration="1.5s">
        <div className="box-icon">
          <i className="pe-7s-global"></i>
        </div>
        <div className="box-content">
          <div className="box-title">
            <h3>Web development</h3>
          </div>
          <div className="box-desc">
            <p>We develop sophisticated browser-based services.</p>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-6">
      <div className="feature-box wow fadeInRight animated" data-wow-duration="1.5s">
        <div className="box-icon">
          <i className="pe-7s-display1"></i>
        </div>
        <div className="box-content">
          <div className="box-title">
            <h3>Web design</h3>
          </div>
          <div className="box-desc">
          <p>We develop sites Turnkey: from design to content. </p>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-6">
      <div className="feature-box wow fadeInLeft animated" data-wow-duration="1.5s">
        <div className="box-icon">
          <i className="pe-7s-joy"></i>
        </div>
        <div className="box-content">
          <div className="box-title">
            <h3>Game development</h3>
          </div>
          <div className="box-desc">
            <p>We develop games of various levels of difficulty. </p>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-6">
      <div className="feature-box wow fadeInRight animated" data-wow-duration="1.5s">
        <div className="box-icon">
          <i className="pe-7s-phone"></i>
        </div>
        <div className="box-content">
          <div className="box-title">
            <h3>Mobile development</h3>
          </div>
          <div className="box-desc">
            <p>We develop mobile applications for iOS and Android. </p>
          </div>
        </div>
      </div>
    </div>

  </div>
);