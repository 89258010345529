import React from 'react';
import SkyLight from 'react-skylight';

import img1 from '../../../img/portfolios/Builder_AI.jpg';
import img1_2 from '../../../img/portfolios/Builder_AI-260x172.jpg';
import img2 from '../../../img/portfolios/Plato.jpg';
import img2_2 from '../../../img/portfolios/Plato-260x172.jpg';
import img3 from '../../../img/portfolios/vitay.jpg';
import img3_2 from '../../../img/portfolios/vitay-260x172.jpg';
// import img4 from '../../../img/portfolios/onelogin-1.png';
import img4_2 from '../../../img/portfolios/onelogin-1-260x172.png';
import img4_3 from '../../../img/portfolios/onelogin-2.png';
// import img4_4 from '../../../img/portfolios/onelogin-3.png';
// import img4_5 from '../../../img/portfolios/onelogin-4.png';
import img4_6 from '../../../img/portfolios/onelogin-5.png';
// import img4_7 from '../../../img/portfolios/onelogin-6.png';

import img5 from '../../../img/portfolios/onelogin-test.jpg';
import img5_2 from '../../../img/portfolios/onelogin-test-260x172.jpg';

import img6_1 from '../../../img/portfolios/nexamp-1.jpg';
import img6_2 from '../../../img/portfolios/nexamp-1-260x172.jpg';
import img6_3 from '../../../img/portfolios/nexamp-2.jpg';

import img7_1 from '../../../img/portfolios/tough_commerce.jpg';
import img7_2 from '../../../img/portfolios/tough_commerce-260x172.jpg';
import img9_1 from '../../../img/portfolios/tough_commerce-test.png';
import img9_2 from '../../../img/portfolios/tough_commerce-test-260x172.png';

import img8_1 from '../../../img/portfolios/cprime.jpg';
import img8_2 from '../../../img/portfolios/cprime-260x172.jpg';


class Portfolio extends React.Component{
   
    render(){

        var myBigGreenDialog = {
            /*backgroundColor: '#00897B',
            color: '#ffffff',*/
            width: '70%',
            height: '600px',
            marginTop: '-290px',
            marginLeft: '-35%',
          };
        
        return(
        <div>
        <div className="row">
        

                    
                       <div className="text-box">
                           <div className="col-md-12">
                               <div className="wow fadeInDown animated" data-wow-duration="1.5s">
                                   <h2 className = 'text-center'>Portfolio</h2>
                               </div>

                           {/*<div  className="wow fadeInUp animated" data-wow-duration="1.5s">*/}

                           {/*     <ul className="filters">*/}
                           {/*         <li>*/}
                           {/*             <a className="active" href="/libra/portfolio/filterable/?cat" data-option-value="*">*/}
                           {/*                 <h5>All</h5>*/}
                           {/*             </a>*/}
                           {/*         </li>*/}
                           {/*         <li><h5>||</h5></li>*/}
                           {/*         <li>*/}
                           {/*             <a href="/libra/portfolio/filterable/?cat=brandidentity" data-option-value=".brandidentity">*/}
                           {/*             <h5>Development</h5>*/}
                           {/*             </a>*/}
                           {/*         </li>*/}
                           {/*         <li><h5>||</h5></li>*/}
                           {/*         <li>*/}
                           {/*             <a href="/libra/portfolio/filterable/?cat=logodesign" data-option-value=".logodesign">*/}
                           {/*             <h5>Testing</h5>*/}
                           {/*             </a>*/}
                           {/*         </li>*/}
                           {/*         <li><h5>||</h5></li>*/}
                           {/*         <li>*/}
                           {/*             <a href="/libra/portfolio/filterable/?cat=webdesign" data-option-value=".webdesign">*/}
                           {/*             <h5>Web Design</h5>*/}
                           {/*             </a>*/}
                           {/*         </li>*/}
                           {/*     </ul>*/}
                           {/*</div>*/}
                           </div>
                        </div>
                    

                    <div className="portfolio">
                        <ul id="portfolio" className="slide-detail detail thumbnails">
                            <li className="first slide-1 filterable_item span3 brandidentity  logodesign  webdesign  first">
                                <div className="ch-item ch-item-hover" style={{background: `url(${img1_2})`}}>
                                    <div className="ch-info" onClick={() => this.customDialog1.show()}>
                                        <div className="ch-info-text">
                                            <p className="title">Builder AI</p>
                                        </div>
                                        <div className="ch-info-icons">
                                            {/*<button ><img src={zoom} alt="Open PopUp" /></button>*/
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     fill="#f1f4f5" className="bi bi-zoom-in" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
                                                    <path
                                                        d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
                                                    <path fillRule="evenodd"
                                                          d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            }
                                        </div>


                                    </div>
                                    <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked
                                              ref={ref => this.customDialog1 = ref}>
                                        <h3>Builder AI</h3>
                                        <img src={img1} alt=""/>
                                        <p>Our company works with archer software, which has several projects, including
                                            the "Builder AI" application automatic build system.</p>
                                        <p>A system of virtual desktops is being developed that can be created and
                                            deleted if necessary. The system is able to create environments on AWS and
                                            Digital Ocean and uses predefined images for different types of development;
                                            backend, frontend, mobile. Includes api and client with face recognition
                                            authentication.</p>
                                    </SkyLight>
                                </div>
                            </li>

                            <li className="filterable_item span3 printdesign  logodesign ">
                                <div className="ch-item ch-item-hover" style={{background: `url(${img2_2})`}}>
                                    <div className="ch-info" onClick={() => this.customDialog2.show()}>
                                        <div className="ch-info-text">
                                            <p className="title">Plato</p>
                                        </div>
                                        <div className="ch-info-icons">
                                            {/*<button onClick={() => this.customDialog2.show()}><img src={zoom} alt="Open PopUp" /></button> */
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     fill="#f1f4f5" className="bi bi-zoom-in" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
                                                    <path
                                                        d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
                                                    <path fillRule="evenodd"
                                                          d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            }
                                        </div>


                                    </div>
                                    <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked
                                              ref={ref => this.customDialog2 = ref}>
                                        <h3>Plato</h3>
                                        <img src={img2} alt=""/>
                                        <p>Plato is a platform for creating an order for an application and managing its
                                            execution. Our company was engaged in the development of the client part of
                                            the application and the implementation of various design solutions.</p>
                                    </SkyLight>
                                </div>
                            </li>

                            <li className="filterable_item span3 brandidentity  logodesign ">

                                <div className="ch-item ch-item-hover" style={{background: `url(${img3_2})`}}>
                                    <div className="ch-info" onClick={() => this.customDialog3.show()}>
                                        <div className="ch-info-text">
                                            <p className="title">Vitay</p>
                                        </div>
                                        <div className="ch-info-icons">
                                            {/*<button onClick={() => this.customDialog3.show()}><img src={zoom} alt="Open PopUp" /></button> */
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     fill="#f1f4f5" className="bi bi-zoom-in" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
                                                    <path
                                                        d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
                                                    <path fillRule="evenodd"
                                                          d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            }
                                        </div>


                                    </div>
                                    <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked
                                              ref={ref => this.customDialog3 = ref}>
                                        <h3>Vitay</h3>
                                        <img src={img3} alt=""/>
                                        <p>Vitay is a recruiting agency-oriented platform that makes it easy to find and
                                            hire candidates. Due to the fact that Vitay allows you to integrate other
                                            services into yourself (Bullhorn, Jobadder, LinkedIn ..etc) and, thereby,
                                            creating a single specialist database, management, synchronization, sending
                                            out offers becomes easier. We have been developing front-end and backend
                                            parts, integrating various services.</p>
                                    </SkyLight>
                                </div>
                            </li>

                            <li className="filterable_item span3 brandidentity  logodesign ">

                                <div className="ch-item ch-item-hover" style={{background: `url(${img4_2})`}}>
                                    <div className="ch-info" onClick={() => this.customDialog4.show()}>
                                        <div className="ch-info-text">
                                            <p className="title">OneLogin</p>
                                        </div>
                                        <div className="ch-info-icons">
                                            {/*<button onClick={() => this.customDialog4.show()}><img src={zoom} alt="Open PopUp" /></button> */
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     fill="#f1f4f5" className="bi bi-zoom-in" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
                                                    <path
                                                        d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
                                                    <path fillRule="evenodd"
                                                          d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            }
                                        </div>


                                    </div>

                                    <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked
                                              ref={ref => this.customDialog4 = ref}>
                                        <h3>OneLogin</h3>
                                        <img src={img4_3} alt=""/>
                                        <img src={img4_6} alt=""/>
                                        <p>We are developing components of the accounting system for access to various
                                            resources. The portal provides a complete solution with multi-step
                                            validation for access. We develop and maintain a portal authentication
                                            system. We also developed one of the latest factors - Onelogin Voice, which
                                            is based on voice messages over the phone. The portal allows you to connect
                                            accounts of various services for the convenience of authentication through
                                            SSO or through the Onelogin portal page, such well-known as: Google Mail,
                                            Atlassian JIRA, Amazon and others. The Portal also allows you to connect and
                                            import users from Active Directory, which also allows you to control user
                                            access to working PCs. </p>
                                    </SkyLight>
                                </div>
                            </li>


                            <li className="filterable_item span3 printdesign  logodesign ">
                                <div className="ch-item ch-item-hover" style={{background: `url(${img5_2})`}}>
                                    <div className="ch-info" onClick={() => this.customDialog5.show()}>
                                        <div className="ch-info-text">
                                            <p className="title">OneLogin testing</p>
                                        </div>
                                        <div className="ch-info-icons">
                                            {/*<button onClick={() => this.customDialog5.show()}><img src={zoom} alt="Open PopUp" /></button>*/
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     fill="#f1f4f5" className="bi bi-zoom-in" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
                                                    <path
                                                        d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
                                                    <path fillRule="evenodd"
                                                          d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            }
                                        </div>


                                    </div>
                                    <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked
                                              ref={ref => this.customDialog5 = ref}>
                                        <h3>OneLogin testing</h3>
                                        <img src={img5} alt=""/>
                                        <p>Testing by the user was carried out from such browsers as Google Chrome,
                                            Safari, Mozilla FX, using an Android device, IOS + mobile phone with an open
                                            national geographical number. Testing on the server side required the above
                                            list of browsers as well as a cloud server, corporate network, Microsoft
                                            Outlook and Office 365, Skype for Business, etc. Cloud service “Sumo Logic”.
                                            Utility for removing Logs “Charles proxy”. A series of some tests took place
                                            using the TestRail test management system and the Jira bug tracking system.
                                            Create test cases and error reports.</p>

                                        <p>Were made: mainly functional testing, regression testing, security testing,
                                            localization testing.</p>
                                    </SkyLight>
                                </div>
                            </li>

                            <li className="filterable_item span3 printdesign  logodesign ">
                                <div className="ch-item ch-item-hover" style={{background: `url(${img6_2})`}}>
                                    <div className="ch-info" onClick={() => this.customDialog6.show()}>
                                        <div className="ch-info-text">
                                            <p className="title">Nexamp testing</p>
                                        </div>
                                        <div className="ch-info-icons">
                                            {/*<button onClick={() => this.customDialog6.show()}><img src={zoom} alt="Open PopUp" /></button>*/
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     fill="#f1f4f5" className="bi bi-zoom-in" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
                                                    <path
                                                        d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
                                                    <path fillRule="evenodd"
                                                          d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            }
                                        </div>


                                    </div>
                                    <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked
                                              ref={ref => this.customDialog6 = ref}>
                                        <h3>Nexamp testing</h3>
                                        <img src={img6_1} alt=""/>
                                        <img src={img6_3} alt=""/>
                                        <p>Nexamp Inc. provides renewable power and clean energy solutions. The Company
                                            provides utility scale photovoltaic installations. Nexamp developes ,
                                            builds, owns, and operates renewable energy projects.</p>
                                        <p>Were made: regression testing, functional testing.
                                            Create test cases and error reports.
                                        </p>
                                    </SkyLight>
                                </div>
                            </li>

                            <li className="filterable_item span3 printdesign  logodesign ">
                                <div className="ch-item ch-item-hover" style={{background: `url(${img7_2})`}}>
                                    <div className="ch-info" onClick={() => this.customDialog7.show()}>
                                        <div className="ch-info-text">
                                            <p className="title">Tough Commerce</p>
                                        </div>
                                        <div className="ch-info-icons">
                                            {/*<button onClick={() => this.customDialog6.show()}><img src={zoom} alt="Open PopUp" /></button>*/
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     fill="#f1f4f5" className="bi bi-zoom-in" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
                                                    <path
                                                        d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
                                                    <path fillRule="evenodd"
                                                          d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            }
                                        </div>


                                    </div>
                                    <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked
                                              ref={ref => this.customDialog7 = ref}>
                                        <h3>Tough Commerce</h3>
                                        <img src={img7_1} alt=""/>
                                        <p>Our team is developing a platform for suppliers of volumetric concrete pumps
                                            and aggregates. We are developing a cloud platform that manages everything
                                            from order entry to reporting and analytics. We are developing an order
                                            management portal that gives manufacturers the flexibility and real-time
                                            autonomy to manage orders on their own terms.</p>
                                        <p>We have developed a complete set of financial tools to automate invoicing,
                                            electronic tickets, payments, loans, taxes and accounting. We have developed
                                            a mobile application and a complete, flexible and modern set of delivery
                                            modules designed for complex industries. Our delivery technology can work
                                            stand-alone or together with deeply integrated logistics and telematics
                                            products.</p>
                                    </SkyLight>
                                </div>
                            </li>

                            <li className="filterable_item span3 printdesign  logodesign ">
                                <div className="ch-item ch-item-hover" style={{background: `url(${img9_2})`}}>
                                    <div className="ch-info" onClick={() => this.customDialog7.show()}>
                                        <div className="ch-info-text">
                                            <p className="title">Tough Commerce testing</p>
                                        </div>
                                        <div className="ch-info-icons">
                                            {/*<button onClick={() => this.customDialog6.show()}><img src={zoom} alt="Open PopUp" /></button>*/
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     fill="#f1f4f5" className="bi bi-zoom-in" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
                                                    <path
                                                        d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
                                                    <path fillRule="evenodd"
                                                          d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            }
                                        </div>


                                    </div>
                                    <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked
                                              ref={ref => this.customDialog7 = ref}>
                                        <h3>Tough Commerce testing</h3>
                                        <img src={img9_1} alt=""/>
                                        <p>Testing by the user was carried out from such browsers as Google Chrome,
                                            Safari, Mozilla FX, using an Android device, IOS + mobile phone with an open
                                            national geographical number. Testing on the server side required the above
                                            list of browsers as well as a cloud server, corporate network, Microsoft
                                            Outlook and Office 365, Skype for Business, etc. Cloud service “Sumo Logic”.
                                            Utility for removing Logs “Charles proxy”. A series of some tests took place
                                            using the TestRail test management system and the Jira bug tracking system.
                                            Create test cases and error reports.</p>

                                        <p>Were made: mainly functional testing, regression testing, security testing,
                                            localization testing.</p>
                                    </SkyLight>
                                </div>
                            </li>

                            <li className="filterable_item span3 printdesign  logodesign ">
                                <div className="ch-item ch-item-hover" style={{background: `url(${img8_2})`}}>
                                    <div className="ch-info" onClick={() => this.customDialog8.show()}>
                                        <div className="ch-info-text">
                                            <p className="title">Cprime</p>
                                        </div>
                                        <div className="ch-info-icons">
                                            {/*<img src={more} alt="Open PopUp"/>*/
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     fill="#f1f4f5" className="bi bi-zoom-in" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
                                                    <path
                                                        d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
                                                    <path fillRule="evenodd"
                                                          d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            }
                                        </div>

                                    </div>
                                    <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked
                                              ref={ref => this.customDialog8 = ref}>
                                        <h3>Cprime</h3>
                                        <img src={img8_1} alt=""/>
                                        <p>We are developing and maintaining an automated system for building
                                            applications for clients. Implementing and certifying new buildings blocks
                                            that are used to automatically create new client applications, maintaining
                                            and upgrading those blocks.</p>
                                        <p>Developing tools to automate manual checks and the pipelines for the process
                                            of creating new blocks.</p>
                                    </SkyLight>
                                </div>
                            </li>

                        </ul>
                    </div>
        </div>
        </div>
        );
    }
}

export default Portfolio;
