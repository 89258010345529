import React from 'react';
import { API_ENDPOINT } from '../../../config'

import { FormErrors } from './FormErrors';

import Alert from 'react-s-alert';
// mandatory
import 'react-s-alert/dist/s-alert-default.css';
 
// optional - you can choose the effect you want
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';





class ContactForm extends React.Component {
  constructor(props) {
    window.asd = props;
    super(props);
    this.state = { 
      name: '', 
      email: '', 
      subject: '', 
      message: '',
      formErrors: {name: '', email: '', subject: '', message: ''},
      nameValid: false,
      emailValid: false,
      subjectValid: false,
      messageValid: false,
      formValid: false
    };
    

    this.onSubmit = this.onSubmit.bind(this);
  }

  handleScaleSuccess = (e) => {
    e.preventDefault();
    Alert.success('Your email has been sent successfully.', {
      position: 'bottom',
      effect: 'scale'
    });
  }
  
  handleScaleError = (e) => {
    e.preventDefault();
    Alert.error('Something is wrong!', {
      position: 'bottom',
      effect: 'scale'
    });
  }


  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
                  () => { this.validateField(name, value) });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let nameValid = this.state.nameValid;
    let emailValid = this.state.emailValid;
    let subjectValid = this.state.subjectValid;
    let messageValid = this.state.messageValid;

    switch(fieldName) {
      case 'name':
        nameValid = value.length >= 1;
        fieldValidationErrors.name = nameValid ? '': ' is required';
       
        break;
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : ' is invalid';
        
        break;
      case 'subject':
        subjectValid = value.length >= 1;
        fieldValidationErrors.subject = subjectValid ? '': ' is required';
        
        break;
      case 'message':
        messageValid = value.length >= 1;
        fieldValidationErrors.message = messageValid ? '': ' is required';
        
        break;
      default:
        break;
    }
    

    this.setState({formErrors: fieldValidationErrors,
                    nameValid: nameValid,
                    emailValid: emailValid,
                    subjectValid: subjectValid,
                    messageValid: messageValid
                  }, this.validateForm);

                  
  }

  validateForm() {
    this.setState({formValid: this.state.nameValid && this.state.emailValid && this.state.subjectValid && this.state.messageValid});
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'form-error');
  }

  onSubmit(event){
    event.preventDefault();
    fetch(`${API_ENDPOINT}/contactus`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
      })
    })
    .then((response) => response.json())
    .then((responseJson) => {
      window.asd = this;
      console.log(responseJson);
      if (responseJson.success) {
        this.setState({ name: '', email: '', subject: '', message: '', formValid: false});
        
        //"Your email has been sent successfully.";
        this.handleScaleSuccess(event);
        
      }
      else {
        //"Something is wrong!";
        this.handleScaleError(event);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }



  render() {
    return (
      <div>
    <h2 className = 'text-center'>Contact us</h2>
    <h3> Don’t Be Shy.</h3>
    <div className="col-md-12">
      <p>We are currently looking for several new interesting projects. If we are interested in you, send your letter and detailed information to <a href="mailto:inbox@indigo-dev.com">inbox@indigo&#8209;dev.com</a> to learn about all the possibilities. </p>
    </div>
    <div className="col-md-12 wow fadeInUp animated" data-wow-duration="1.5s">
      <div className="row">
        <form id="contactform"  onSubmit={this.onSubmit}>

          <div className="col-md-4">
            <div className={`form-group ${this.errorClass(this.state.formErrors.name)}`}>
              <input type="text" className="form-control " required placeholder="Name" name="name" id="name" value={this.state.name} onChange={this.handleUserInput} />
            </div>
            
          </div>

          <div className="col-md-4">
            <div className={`form-group  ${this.errorClass(this.state.formErrors.email)}`}>
              <input type="text" className="form-control email" required placeholder="Email" name="email" id="email" value={this.state.email} onChange={this.handleUserInput} />
            </div>
          </div>

          <div className="col-md-4">
            <div className={`form-group  ${this.errorClass(this.state.formErrors.subject)}`}>
              <input type="text" className="form-control" required placeholder="Subject" name="subject" id="subject" value={this.state.subject} onChange={this.handleUserInput} />
            </div>
          </div>

          <div className="col-md-12">
            <div className={`form-group  ${this.errorClass(this.state.formErrors.message)}`}>
              <textarea rows="10" className="form-control" required placeholder="Message" name="message" id="message" value={this.state.message} onChange={this.handleUserInput}></textarea>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-message" hidden={!(this.state.formErrors.name || this.state.formErrors.email || this.state.formErrors.subject || this.state.formErrors.message)}>
              <FormErrors formErrors={this.state.formErrors} />
            </div>
          </div>
          <div className="col-md-12">
            <button type="submit" id="nesto-submit" className="btn btn-lg btn-nesto" disabled={!this.state.formValid}>
              Send Now
            </button>
          </div>

          
          {/*  <a href="#" className="button buttonInfo" onClick={this.handleScaleSuccess}>success</a><br />
          <a href="#" className="button buttonInfo" onClick={this.handleScaleError}>error</a>   */}
          <Alert stack={true} timeout={3000} />
        </form>
      </div>
    </div>
  </div>
      );
    }
  }
  export default ContactForm;
