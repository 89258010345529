import React from 'react';
import { Link} from "react-scroll";
import logo from '../../../img/logo_indigodev.png';

export default () => (
    <div className="footer-holder">
				<div className="row">
					<div className="col-md-3">
						<div className="logo">
                        <Link id="logo" activeclassname="active" to = 'section1' spy={true} smooth={true}  offset={-70} duration= {500}><img src={ logo } className="nav-logo"  alt="IndigoDev" /*onClick={this.scrollToTop}*/ /></Link>
                        </div>
						<p>To new web heights with Indigo Dev<br /> Knowledgeable. Experienced. Friendly.</p>
					</div>
					<div className="col-md-3">
						<div className="button_social_group">
                            <ul>
                                <li id="social-in-menu">
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/indigodev.company" title="Facebook">
                                        <i className="fa fa-facebook"></i>
                                    </a>
                                    
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/indigo-dev" title="LinkedIn">
                                        <i className="fa fa-linkedin"></i>
                                    </a>
                                    
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/indigo.dev/" title="Instagram">
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                </li>

                            </ul>
                        </div>
					</div>
					<div className="col-md-3">
						<div className="text-holder">
							<strong className="phone"><a href="tel:3475677890">+38 (063) 815 83 69</a></strong>
							<span className="available">Available from 12PM - 6PM</span>
							<h4>LLC &laquo;INDIGO DEV&raquo;<br /> Kyiv, Ukraine</h4>
							<address></address>
						</div>
					</div>
					<div className="col-md-3">
						<div className="text-frame">
							<h3>Info</h3>
							<p>HIGH QUALITY. STUNNING DESIGN.<br />
Take your business to new heights with Indigo Dev</p>
						</div>
					</div>
				</div>
			</div>
  )