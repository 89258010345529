import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

import Sergey_Gaychuk from '../../../img/testimonials/Sergey Gaychuk.jpg';
import Johnny_ONeill from '../../../img/testimonials/Johnny ONeill.jpg';
import Olga_Kovtun from '../../../img/testimonials/Olga Kovtun.jpg';
import Oleksii_Valuiskyi from '../../../img/testimonials/Oleksii Valuiskyi.jpg';
import Illia_Litviak from '../../../img/testimonials/Illia Litviak.jpg';


const content = [
	{
		//title: 'Vulputate Mollis Ultricies Fermentum Parturient',
		description:
		"IndigoDev constantly amazed me with his business insight and unique techniques to solving problems. I would recommend this company with any project that requires the very best in development execution. Give them the really challenging tasks, the ones that everyone else is struggling with, and they will get them done. Besides being a joy to work with this guys, they are take-charge persons who are able to present creative solutions to complex problems and communicate the benefits to the company. I thoroughly appreciated dealing with them at Air Black Box.",
		//button: 'Read More',
		//image: 'https://i.imgur.com/ZXBtVw7.jpg',
		user: "Sergey Gaychuk",
    userProfile:  Sergey_Gaychuk ,
    entity: "Lead ROR/Front Engineer at BESK"
	},
	{
		//title: 'Tortor Dapibus Commodo Aenean Quam',
		description:
		"Pavel is an effective communicator and was able to successfully motivate his team through a number of high pressure projects. I wish him all the success in the future!",
		//button: 'Discover',
		//image: 'https://i.imgur.com/DCdBXcq.jpg',
		user: "Johnny O'Neill",
    userProfile:  Johnny_ONeill ,
    entity: "Delivery & Process Manager at Air Black Box Company Ltd"
	},
	{
		//title: 'Phasellus volutpat metus',
		description:
		"As a software developer, Pavel is strong and professional software engineer with deep knowledge of Ruby, databases, algorithms, data structures and is able to learn very quickly. He always has a plan. No chance for unexpected troubles during development. It’s very exquisite ability even for Team Lead. Together with his technical proficiency and cutting edge frameworks knowledge Pavel is also a reliable manager with strong communication and facilitation skills. I can only give my best recommendations to Pavel.",
		//button: 'Buy now',
		//image: 'https://i.imgur.com/DvmN8Hx.jpg',
		user: "Olga Kovtun",
    userProfile:  Olga_Kovtun ,
    entity: "Ruby on Rails Developer at A S WOOD LIMITED"
  },
  {
		//title: 'Phasellus volutpat metus',
		description:
		"Working with IndigoDev, I found many people there with great experience and deep knowledge of solutions. This company has many dedicated, open-minded and open-minded developers, their knowledge is broad and thorough. Deadline-oriented perfectionists who can be trusted. I enjoyed working with them on the various projects we worked on.",
		//button: 'Buy now',
		//image: 'https://i.imgur.com/DvmN8Hx.jpg',
		user: "Oleksii Valuisky",
    userProfile:  Oleksii_Valuiskyi ,
    entity: "Lead Developer at Maxitech Software"
	},
  {
		//title: 'Phasellus volutpat metus',
		description:
		"IndigoDev is one of the best companies I have ever worked with. The people working here are very productive, hardworking, broad-minded and forward-thinking individuals. Smart, ambitious, energetic and proactive perfectionists. Pavel's passion for training and education makes him a valuable asset to the team. Working with the IndigoDev team is the signature of success.",
		//button: 'Buy now',
		//image: 'https://i.imgur.com/DvmN8Hx.jpg',
		user: "Illia Litviak",
    userProfile:  Illia_Litviak ,
    entity: "Software Architect at Insured Connect"
	}
];
/*ArrowLeft = () => {

}*/

export default () => (
  <div>
    
    <div className="wow fadeInDown animated" data-wow-duration="1.5s">
      <h2>People Are Talking About Us</h2>
    </div>
    
    <Slider className="slider-wrapper wow fadeInUp animated"  data-wow-duration="1.5s" >
			{content.map((item, index) => (
				<div
					key={index}
          className="slider-content"
				//	style={{ background: `url('${item.image}') no-repeat center center` }}
				>
        <section>
						<img src={item.userProfile} alt={item.user} />
						<span>
						  <strong>{item.user}</strong>
              {item.entity}
						</span>
					</section>
					<div className="inner">
						
						<p>{item.description}</p>
						
					</div>
					
				</div>
			))}
		</Slider>

  </div>
);
