import React  from 'react';
import $ from 'jquery';

import Header from '../../components/common/Header';
import Navbar from '../../components/common/Navbar';
import Footer from '../../components/common/Footer';
import About from '../../components/pages/About';
import Services from '../../components/pages/Services';
import Skills from '../../components/pages/Skills';
import Technologies from '../../components/pages/Technologies';
import Contact from '../../components/pages/Contacts';
import Testimonials from '../../components/pages/Testimonials';

import img_decor from '../../img/Web-Development.jpg';

import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import Portfolio from '../../components/pages/Portfolio';
// import AwesomeContainer from '../../components/common/AwesomeContainer/AwesomeContainer'



  const HeroBanner = ({ image, min, max, children }) => (
    <div className="hero-container">
        <Parallax offsetYMin={min} offsetYMax={max} slowerScrollRate>
            <div
                className="hero-image"
                style={{ backgroundImage: `url(${image})` }}
            />
        </Parallax>
        <div className="hero-children">{children}</div>
    </div>
);

$(window).scroll(function(){
  if ($(this).scrollTop() > 100) {
      $('.header').addClass('fixed');
  } else {
      $('.header').removeClass('fixed');
  }
});

export default () => {
  return (
  
<div id="wrapper">


  <div className = "container">
  <header id="header" className="header">
    <div className = "header-container">
      <Header />
      <Navbar />
    </div>
  </header>
  </div>
 <section className="visual" id="section1">
  {/* -----basic config-----*/}
  <ParallaxProvider>

            <HeroBanner
                min={'-20%'}
                max={'20%'}
                image={ img_decor }
            >
            <div className = "container">
              <div className="text-block">
                <div className="heading-holder">
                  <h1>To new web heights with Indigo Dev</h1>
                </div>
                <p className="tagline">Knowledgeable. Experienced. Friendly.</p>
                <span className="info"></span>
              </div>
            </div>
            </HeroBanner>
        

    
</ParallaxProvider>
    
</section>

  <section className="main"  id="section2">
    <div className="container">
      <Services />
    </div>
  </section>
  
  
  <section className="main"  id="section3">
    <div className="container">
      <Skills />
    </div>
  </section>

  <section className="main" id="section7" >
    <div className="container">
      <Technologies />
    </div>
  </section>

    <section className="main"  id="section4">
    <div className="container">
      <Testimonials />
    </div>
  </section>

  <section className="main"  id="section5">
  
    <div className="container">
      <About />
    </div>
  </section>
  <section className="main"  id="section8">
  
    <div className="container">

      <Portfolio />
    </div>
  </section>
  <section className="main"  id="section6">
    <div className="container">
      <Contact/>
    </div>
  </section>
  <footer id="footer">
    
      <Footer />
    
  </footer>
</div>
);
}
