import React from 'react';



export default () => (
  <div className="row">
    <div className="col-md-12 wow fadeInDown animated" data-wow-duration="1.5s">
        <h2 className = 'text-center'>Our skills</h2>
    </div>
    <div className="skills wow pulse animated" data-wow-duration="1.5s">
     <div className="col-md-3">
        <div className="box-icon">
          <i className="pe-7s-global"></i>
        </div>
        <h4>Web development</h4>
     </div>
     <div className="col-md-3">
        <div className="box-icon">
          <i className="pe-7s-phone"></i>
        </div>
        <h4>Mobile development</h4>
        </div>
     <div className="col-md-3">
        <div className="box-icon">
          <i className="pe-7s-joy"></i>
        </div>
        <h4>Game development</h4>
     </div> 
     <div className="col-md-3">
        <div className="box-icon">
          <i className="pe-7s-display2"></i>
        </div>
        <h4>Front-end development</h4>
     </div>



     <div className="col-md-3">
        <div className="box-icon">
          <i className="pe-7s-network"></i>
        </div>
        <h4>Back-end development</h4>
     </div>
     <div className="col-md-3">
        <div className="box-icon">
          <i className="pe-7s-users"></i>
        </div>
        <h4>Manual testing</h4>
        </div>
     <div className="col-md-3">
        <div className="box-icon">
          <i className="pe-7s-config"></i>
        </div>
        <h4>Automation testing</h4>
     </div> 
     <div className="col-md-3">
        <div className="box-icon">
          <i className="pe-7s-rocket"></i>
        </div>
        <h4>Product management</h4>
     </div>



    </div>

  </div>
);