import React from 'react';
import ruby from '../../../img/technologies/ruby.png';
import ruby_on_rails from '../../../img/technologies/ruby_on_rails.png';
import react_native from '../../../img/technologies/react-native.png';
import React_logo from '../../../img/technologies/React.png';
import postgresql from '../../../img/technologies/postgresql.png';
import Java from '../../../img/technologies/Java.png';
import node_js from '../../../img/technologies/node-js.png';
import angular from '../../../img/technologies/angular.svg';
import MySQL from '../../../img/technologies/MySQL.png';
import javascript from '../../../img/technologies/javascript.png';
import html5 from '../../../img/technologies/html5.png';
import css3 from '../../../img/technologies/css3.png';


export default () => (
  <div className="row">
    <div className="col-md-12 wow fadeInDown animated" data-wow-duration="1.5s">
        <h2 className = 'text-center'>Our technologies</h2>
    </div>
    <div className="technologies">
     
      <div className="col-md-3">
        <img src={ ruby } className=""  alt="" />
      </div>
      <div className="col-md-3">
        <img src={ html5 } className=""  alt="" />
      </div>
      <div className="col-md-3">
        <img src={ React_logo } className=""  alt="" />
      </div>

      <div className="col-md-3">
        <img src={ Java } className=""  alt="" />
      </div>
      <div className="col-md-3">
      <img src={ ruby_on_rails } className=""  alt="" />
        
      </div>
      <div className="col-md-3">
      <img src={ css3 } className=""  alt="" />
        
      </div>

      <div className="col-md-3">
      <img src={ MySQL } className=""  alt="" />
      </div>
      <div className="col-md-3">
      <img src={ node_js } className=""  alt="" />
      </div>
      <div className="col-md-3">
      <img src={ angular } className=""  alt="" />
        
      </div>

      <div className="col-md-3">
      <img src={ javascript } className=""  alt="" />
      </div>
      <div className="col-md-3">
      <img src={ react_native } className=""  alt="" />
      </div>
      <div className="col-md-3">
        <img src={ postgresql } className=""  alt="" />
      </div>
    
    
    </div>

  </div>
);