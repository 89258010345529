import React                from 'react';

import Default              from './layouts/Default';
import Public               from './routes/Public';
import Home                 from './components/pages/Home'
import Contacts             from './components/pages/Contacts'
import About                from './components/pages/About'
import Services             from './components/pages/Services'
import Skills               from './components/pages/Skills'
import Technologies         from './components/pages/Technologies'
import Testimonials         from './components/pages/Testimonials'
import Portfolio         from './components/pages/Portfolio'

export default () => (
  <div className="App">
    <Public layout = { Default } path = '/' exact   component = { Home } />
    <Public layout = { Default } path = '/about'    component = { About } />
    <Public layout = { Default } path = '/services' component = { Services } />
    <Public layout = { Default } path = '/skills' component = { Skills } />
    <Public layout = { Default } path = '/technologies' component = { Technologies } />
    <Public layout = { Default } path = '/testimonials' component = { Testimonials } />
    <Public layout = { Default } path = '/portfolio' component = { Portfolio } />
    <Public layout = { Default } path = '/contacts' component = { Contacts } />
  </div>
);
