import React from 'react';
import { Link} from "react-scroll";

import { Component } from 'react';


class YourClass extends Component {
  constructor(props) {
        super(props);
        this.state = {class: ""};
          
        this.press = this.press.bind(this);
    }
press(){
    let className = (this.state.class==="")?"nav-active":"";
    this.setState({class: className});
}
render() {
    
    return (
      
    <div  className={this.state.class}>
        <div  className="opener-holder">
          <span className="nav-opener" onClick={this.press}><span></span></span>
        </div>
        <div className="nav-drop">
          <ul>
            <li><Link className = 'nav-link' onClick={this.press} activeclassname="active" to = 'section2' spy={true} smooth={true}  offset={-70} duration= {500}>Services</Link></li>
            <li><Link className = 'nav-link' onClick={this.press} activeclassname="active" to = 'section3' spy={true} smooth={true}  offset={-70} duration= {500}>Skills</Link></li>
            <li><Link className = 'nav-link' onClick={this.press} activeclassname="active" to = 'section4' spy={true} smooth={true}  offset={-70} duration= {500}>Testimonials</Link></li>
            <li><Link className = 'nav-link' onClick={this.press} activeclassname="active" to = 'section5' spy={true} smooth={true}  offset={-70} duration= {500}>About</Link></li>
            <li><Link className = 'nav-link' onClick={this.press} activeclassname="active" to = 'section8' spy={true} smooth={true}  offset={-70} duration= {500}>Portfolio</Link></li>
            <li><Link className = 'nav-link' onClick={this.press} activeclassname="active" to = 'section6' spy={true} smooth={true}  offset={-70} duration= {500}>Contact</Link></li>
          </ul>
        </div>
    </div>
   
    )

  }
}


export default () => (
  
  <nav id="nav">
      <YourClass />
  </nav>
);