import React from 'react';
/*import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';*/


export default () => (
  <div>
  <div className="text-block">
    <div className="heading-holder">
      <h1>Fork App</h1>
    </div>
    <p className="tagline">Best Technology To Your  Web Market</p>
    <span className="info">Knowledgeable. Experienced. Friendly.</span>
  </div>
  
  </div>
);
/*function App() {
  return (
    <Button variant="contained" color="primary">
      
    </Button>
  );
}

ReactDOM.render(<App />, document.querySelector('#app'));*/