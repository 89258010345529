//import 'core-js/stable';
//import "@babel/polyfill";

import React              from 'react';
import ReactDOM           from 'react-dom';
import { BrowserRouter }  from 'react-router-dom';

import './assets/styles/index.scss';

import App                from './App';
import * as serviceWorker from './serviceWorker';

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
